import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '@/components/LoginView.vue'
import WelcomeView from "@/components/WelcomeView.vue";
import {IS_USER_AUTHENTICATED} from "@/store/storeconstants";
import TerrainView from "@/components/TerrainView.vue";

const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: "/",
    name: 'home',
    component: WelcomeView,
  },
  {
    path: "/site/:id",
    name: 'site',
    component: TerrainView,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})
router.beforeEach(async (to) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const isAuthenticated = router.store.getters[`auth/${IS_USER_AUTHENTICATED}`];
  if (authRequired && !isAuthenticated) {
    return '/login';
  }
});
export default router
