import { createStore } from 'vuex'
import auth from './auth/index'
import createPersistedState from 'vuex-persistedstate'
export default createStore({
    state: {
    },
    plugins: [createPersistedState({
        storage: window.sessionStorage,
    })],
    getters: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
        auth
    }
})