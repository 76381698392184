import { createApp } from 'vue'
import App from './App.vue'
import { createI18n } from 'vue-i18n'
import router from './router'
import store from "@/store";
import "@/style.css";
import mitt from 'mitt';


const emitter = mitt();

import { languages } from '@/i18n/index.js'
import { defaultLocale } from '@/i18n/index.js'
const messages = Object.assign(languages)
const i18n = createI18n({
    locale: defaultLocale,
    fallbackLocale: 'fr',
    messages,
});
const app = createApp(App);
app.use(i18n);
app.use(store)
app.use(router)
router.store = store
app.config.globalProperties.emitter = emitter;
app.mount('#app')
