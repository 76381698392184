import router from "@/router";
import {GET_TOKEN} from "@/store/storeconstants";

class RealtimeClass {
    blob;

    constructor() {
        let self = this;
        self.blob = {};
        self.blob.userConnection = null;
        self.blob.userConnected = false;
        self.blob.userConnectionCallback = null;
        self.blob.dataCallback = null;
    }

    setUserConnectionCallback = (callback) => {
        let self = this;
        self.blob.userConnectionCallback = callback;
    }
    setDataCallback = (callback) => {
        let self = this;
        self.blob.dataCallback = callback;
    }
    disconnect = () => {
        let self = this;
        self.blob.dataCallback = null
        self.blob.userConnectionCallback = null
        self.blob.userConnection.close();
        self.blob.userConnection = null;
    }
    connect = () => {
        let self = this;
        if (self.blob.userConnection != null) {
            return;
        }
        let token = router.store.getters[`auth/${GET_TOKEN}`];
        self.blob.userConnection = new WebSocket("wss://llenxrealtime.plouidy.fr?token=" + token);
        self.blob.userConnection.onmessage = (event) => {
            let data = event.data;
            try {

                const obj = JSON.parse(data);
                if (self.blob.dataCallback != null) {
                    self.blob.dataCallback(obj);
                }
            } catch(e) {
                console.log(e);
                console.log("json error");
            }
        }
        self.blob.userConnection.onopen = () => {
            console.log("Connection opened");
            self.blob.userConnected = true;
            self.sendUserConnectionStatus();
        }
        self.blob.userConnection.onclose = () => {
            console.log("Connection close");
            self.blob.userConnected = false;
            self.sendUserConnectionStatus();
        }
    }
    sendUserConnectionStatus = () => {
        let self = this;
        if (self.blob.userConnectionCallback) {
            self.blob.userConnectionCallback(self.blob.userConnected);
        }
    }

    sendMessage = (type, value, param) => {
        let self = this;
        if (self.blob.userConnection) {
             let sendCallback = (t,v,p) =>{
                if (self.blob.userConnection.readyState === 1){
                    self.blob.userConnection.send(JSON.stringify({
                        type: type,
                        value: value,
                        param: param
                    }))
                }
                else {
                    setTimeout(()=>sendCallback(t,v,p),500)
                }
            }
            sendCallback(type,value,param);
        }
    }
    getUserConnectionStatus = () => {
        let self = this;
        self.sendUserConnectionStatus();
    }
 }

const realtime = new RealtimeClass();
Object.freeze(realtime);

export default realtime;
