<template>
  <div class="h-screen overflow-y-hidden">
    <NavBarView
        :items="[ {name: $t('message.home'),action: 'home',id: '1'}, {name: $t('message.logout'),action: 'logout',id: '2'}]"/>
    <div class="bg-gray-200 text-gray-200 py-3.5 px-6 shadow md:flex justify-between
  items-center">
      <h1 class="text-black">{{ this.site.name }}</h1>
      <div class="flex space-x-4">
        <button
            id="start"
            @click="start()"
            :class="['py-2 px-4 rounded', 'bg-blue-500 text-white','hidden']"
        >
          Start recording
        </button>
        <button
            id="stop"
            @click="stop()"
            :class="['py-2 px-4 rounded', 'bg-red-500 text-white animate-pulse','hidden']"
        >
          Stop recording
        </button>
        <button
            id="status"
            @click="getStatus()"
            :class="['py-2 px-4 rounded', 'bg-blue-500 text-white','hidden']"
        >
          Get Status
        </button>
        <button
            id="reload"
            @click="reload()"
            :class="['py-2 px-4 rounded', 'bg-green-500 text-white']"
        >
          Refresh
        </button>

      </div>
    </div>
    <PatentPOCView/>
  </div>
</template>

<script>
import {GET_TOKEN, SET_AUTHENTICATION, SET_TOKEN} from "@/store/storeconstants";
import {gateway, site} from "@/api/Services";
import NavBarView from "@/components/NavBarView.vue";
import realtime from "@/classes/realtime";
import PatentPOCView from "@/components/PatentPOCView.vue";

export default {

  name: 'TerrainView',
  components: {PatentPOCView, NavBarView},
  data() {
    return {
      site: {
        name: "",
      },
      peer_connected: false,
      connected_status: false,
      connection: null,
      //TODO Hardcode
      peer_id: 1,
      date: "-",
      selectedButton: "live",
      viewMode: "v1",
      replay_status: "off",
      lidar_running: false,
      lidar_recording: false,
      gateway_connected: false,

    };
  },
  methods: {
    writeMessage(message, style = "") {
      let foo = document.getElementById("container");
      let currentDate = new Date();
      let trace = "[" + currentDate.getDate() + "/"
          + (currentDate.getMonth() + 1) + "/"
          + currentDate.getFullYear() + " @ "
          + currentDate.getHours() + ":"
          + currentDate.getMinutes() + ":"
          + currentDate.getSeconds() + "] :: " + message;
      foo.innerHTML += "<p class=\"" + style + "\">" + trace + "</p>";
      foo.scrollTop = foo.scrollHeight;
    },
    getGatewayDetails(gatewayId) {
      let self = this;
      let token = this.$store.getters[`auth/${GET_TOKEN}`];
      gateway(token, gatewayId).then((result) => {
        for (const [key, value] of Object.entries(result.data.gateways)) {
          console.log(key);
          this.writeMessage("Gateway details:[" + value.name + "," + value.type + "," + value.mac_address + "]", "text-blue-500");
          this.writeMessage("Get gateway realtime status");
          self.peer_id = value.id;
          realtime.sendMessage("command", "get_connection_status", value.id);
        }
      });
    },
    getSiteInfo(siteId) {
      let token = this.$store.getters[`auth/${GET_TOKEN}`];
      site(token, siteId).then((result) => {

        this.$nextTick(() => {

          this.writeMessage("Site name: " + result.data.site.name, "text-blue-500");
          this.site.name = result.data.site.name;

          for (const [key, value] of Object.entries(result.data.site.elements)) {
            console.log(key);
            if (value.keyword === "HOOK") {
              this.writeMessage("HOOK detected:" + "[id=" + value.id + ", name=" + value.name + "]", "text-blue-500");
              this.writeMessage("Get gateway details");
              this.getGatewayDetails(value.id);
            }
          }
        });
      });
    },
    logout() {
      this.$store.commit(`auth/${SET_AUTHENTICATION}`, false);
      this.$store.commit(`auth/${SET_TOKEN}`, "");
      window.sessionStorage.clear();
      this.$router.push('/login')
    },
    clear() {
      let foo = document.getElementById("container");
      foo.innerHTML = "";
    },
    sendParams() {
      this.connection.send(JSON.stringify({}));
    },

    isConnected: function () {
      return false;
    },
    start() {
      realtime.sendMessage("poc_user_command", "start", "now");
    },
    stop() {
      realtime.sendMessage("poc_user_command", "stop", "now");
    },
    getStatus() {
      realtime.sendMessage("poc_user_command", "get_status", "now");
    },
    reload() {
      location.reload()
    },
    manageButtons() {
      document.getElementById("start").classList.remove("hidden");
      document.getElementById("start").classList.remove("visible");
      document.getElementById("stop").classList.remove("hidden");
      document.getElementById("stop").classList.remove("visible");
      document.getElementById("status").classList.remove("hidden");
      document.getElementById("status").classList.remove("visible");
      if (!this.gateway_connected) {
        document.getElementById("start").classList.add("hidden");
        document.getElementById("stop").classList.add("hidden");
        document.getElementById("status").classList.add("hidden");
      } else {
        document.getElementById("status").classList.add("visible");

        if (!this.lidar_running) {
          document.getElementById("start").classList.add("hidden");
          document.getElementById("stop").classList.add("hidden");
        } else {
          if(this.lidar_recording) {
            document.getElementById("start").classList.add("hidden");
            document.getElementById("stop").classList.add("visible");
          }else{
            document.getElementById("start").classList.add("visible");
            document.getElementById("stop").classList.add("hidden");
          }
        }
      }
    },
    init() {
      this.clear();
      realtime.connect();

      let connectionCallback = (status) => {
        this.writeMessage(status ? "Connected" : "Disconnected", status ? "text-green-500" : "text-red-500");

        if (status) {
          this.writeMessage("Get site info");
          let siteId = this.$route.params.id
          this.getSiteInfo(siteId);
        }
      }

      realtime.setUserConnectionCallback(connectionCallback);
      this.writeMessage("User connection");
      realtime.getUserConnectionStatus();

      let dataCallback = (parsedObject) => {
        let self = this;
        if (parsedObject.type === "poc_gateway_status") {
          if (parsedObject.param === self.peer_id) {
            let jsonObject = JSON.parse(parsedObject.value);
            this.writeMessage("Gateway status: " + jsonObject.tag, jsonObject.tag === "connected" ? "text-green-600" : "text-red-500");
            this.gateway_connected = jsonObject.tag === "connected";
          }
        }
        if (parsedObject.type === "poc_gateway_app_status") {
          if (parsedObject.param === self.peer_id) {
            let jsonObject = JSON.parse(parsedObject.value);
            if (jsonObject.tag === "disk_space" || jsonObject.tag === "databases_details") {
              this.writeMessage("Lidar status: " + jsonObject.tag + "=" + jsonObject.value, "text-blue-800");
            } else {
              this.writeMessage("Lidar status: " + jsonObject.tag + "=" + jsonObject.value, jsonObject.value === "false" ? "text-red-500" : "text-green-600");
            }
            if (jsonObject.tag === "lidar_running") {
              this.lidar_running = jsonObject.value === "true";
            }
            if (jsonObject.tag === "lidar_recording") {
              this.lidar_recording = jsonObject.value === "true";
            }
          }
        }

        this.manageButtons();
      }
      realtime.setDataCallback(dataCallback);
    }
  },

  beforeUnmount: () => {
    realtime.disconnect();
  },
  mounted() {
    this.init();
  },

}
</script>